import React from 'react';

import './Hero.scss';

const Hero = () => (
  <>
    <div className="hero__container">
      <div className="hero__layerBackground" />
      <div className="hero__layerForeground" />
      <div className="hero__layerClaudia" />
    </div>
    <div className="hero__adventsKugel" tabIndex={-1} />
  </>
);

export default Hero;
